import { SimpleGrid, Flex } from '@chakra-ui/react'
import team from '../../../assets/skills.png'
import leader from '../../../assets/office.png'
import service from '../../../assets/customer-service2.png'
import { FlexCard } from '../../../components/FlexCard'
import StyledHeading from '../../../components/StyledHeading'
import { ColoredLine } from '../../../components/ColoredLine'
export const Strengths = () => {
  return (
    <Flex direction='column' alignItems='center' bg='blue.700' py='60px'>
      <StyledHeading mb='20px' color='whiteAlpha.900'>
        NUESTRAS FORTALEZAS
      </StyledHeading>
      <ColoredLine color='#fff' />
      <SimpleGrid
        columns={3}
        justifyContent='center'
        ml='291px'
        mr='291px'
        mb='40px'
        mt='60px'
        spacing={10}
      >
        <FlexCard
          title='EMPRESA INNOVADORA'
          image={leader}
          desc='Nos focalizamos en brindar soluciones creativas y rápidas a los
        problemas.'
        />
        <FlexCard
          title='PERSONAL CALIFICADO'
          image={team}
          desc='Contamos con ingenieros, técnicos, licenciados y
        bioquímicos altamente calificados en nuestro país y en el exterior.'
        />
        <FlexCard
          title='ATENCION PERSONALIZADA'
          image={service}
          desc='A través de chat, mail, via telefónica o personalmente.'
        />
      </SimpleGrid>
    </Flex>
  )
}
