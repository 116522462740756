import { Text, Flex } from '@chakra-ui/react'

export const Footer = () => {
  return (
    <Flex justifyContent='center' alignItems='center'>
      {/* <Text textTransform='uppercase' fontWeight={400}>
        Copyright © 2022 All Rights Reserved by Materbionet SRL
      </Text> */}
    </Flex>
  )
}
