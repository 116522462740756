import { Flex, Heading, Text, Image } from '@chakra-ui/react'

export const FlexCard = ({ title, desc, image }: any) => {
  return (
    <Flex>
      <Flex width='5.5rem' p='0 0.75rem'>
        <Image src={image} height='64px' />
      </Flex>
      <Flex flexBasis='0' flexGrow='1' p='0 0.75rem' direction='column'>
        <Heading
          as='h3'
          fontSize='1.2rem'
          fontWeight={600}
          color='whiteAlpha.900'
        >
          {title}
        </Heading>
        <Text
          fontSize='0.9rem'
          lineHeight={1.8}
          mt='0.5rem'
          fontWeight={400}
          color='whiteAlpha.700'
        >
          {desc}
        </Text>
      </Flex>
    </Flex>
  )
}
