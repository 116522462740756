import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Landing } from './pages/landing/Landing'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/400.css'
import './styles/index.scss'
const theme = extendTheme({
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
})

export default theme

export const App = () => (
  <ChakraProvider theme={theme}>
    <Header />
    <Landing />
    <Footer />
  </ChakraProvider>
)
