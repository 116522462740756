import { Box } from '@chakra-ui/react'
import { Beliefs } from './sections/Beliefs'
import { Strengths } from './sections/Strengths'
import { Affiliates } from './sections/Affiliates'
import { Contact } from './sections/Contact'
import { WhoAreWe } from './sections/WhoAreWe'

export const Landing = () => {
  return (
    <Box>
      <WhoAreWe />
      <Beliefs />
      <Strengths />
      <Affiliates />
      <Contact />
    </Box>
  )
}
