import { useEffect, useState } from 'react'

import { BiMenuAltRight } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { Heading, HStack, Image } from '@chakra-ui/react'
import slogo from '../assets/small_logo.jpg'

import classes from './Header.module.scss'

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false)
    }
  }, [size.width, menuOpen])

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p)
  }

  return (
    <header className={classes.header}>
      <div className={classes.header__content}>
        <HStack>
          <Image src={slogo} boxSize='90px' />
          <Heading
            color='blackAlpha.900'
            fontSize={16}
            fontWeight={600}
            letterSpacing='1.7px'
          >
            MATERBIONET SRL
          </Heading>
        </HStack>
        <nav
          className={`${classes.header__content__nav} ${
            menuOpen && size.width < 768 ? classes.isMenu : ''
          }`}
        >
          <ul>
            <li>
              <a href='https://forms.gle/NUD4f7kySMDh91JL7'>PEDIDOS</a>
            </li>
            <li>
              <a>¿QUIENES SOMOS?</a>
            </li>
            <li>
              <a>¿QUIENES CONFIAN EN NOSOTROS?</a>
            </li>
            <li>
              <a>CONTACTO</a>
            </li>
          </ul>
        </nav>
        <div className={classes.header__content__toggle}>
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
  )
}
