export const ColoredLine = ({ color }: any) => (
  <hr
    style={{
      borderTop: `2px solid ${color}`,
      margin: 'auto',
      marginTop: 5,
      marginBottom: 5,
      width: 40,
    }}
  />
)
