import { Box, Flex, SimpleGrid, Image } from '@chakra-ui/react'
import asinteg from '../../../assets/asinteg.png'
import belgrano from '../../../assets/belgrano.jpg'
import bg from '../../../assets/bg.png'
import drofast from '../../../assets/drofast.jpg'
import medisistem from '../../../assets/medisistem.jpg'
import roche from '../../../assets/roche.png'
import sananaliticos from '../../../assets/sanaliticos.png'
import siemens from '../../../assets/siemens.png'
import solfacar from '../../../assets/solfacar.jpg'
import lumafra from '../../../assets/lumafra.png'
import { ColoredLine } from '../../../components/ColoredLine'
import StyledHeading from '../../../components/StyledHeading'
export const Affiliates = () => {
  return (
    <Box letterSpacing={1} py='40px'>
      <Flex alignItems='center' justifyContent='center' mb='20px'>
        <StyledHeading color='#333333'>
          QUIENES CONFIAN EN NOSOTROS
        </StyledHeading>
      </Flex>
      <ColoredLine color='#333' />
      <SimpleGrid
        columns={4}
        spacing={10}
        ml={291}
        mr={291}
        mb='40px'
        mt='60px'
        spacingY='40px'
        justifyItems='center'
      >
        <Image src='' height='64px'/>
        <Image src={roche} height='64px' />
        <Image src={siemens} height='64px' />
        <Image src='' height='64px'/>
        <Image src={asinteg} height='64px' />
        <Image src={belgrano} height='64px' />
        <Image src={bg} height='64px' />
        <Image src={drofast} height='64px' />
        <Image src={medisistem} height='64px' />
        <Image src={sananaliticos} height='64px' />
        <Image src={solfacar} height='64px' />
        <Image src={lumafra} height='64px' />
      </SimpleGrid>
    </Box>
  )
}
