import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  SimpleGrid,
} from '@chakra-ui/react'
import slogo from '../../../assets/logo_white.png'
import StyledHeading from '../../../components/StyledHeading'
import { MdLocationOn, MdPhone, MdMail } from 'react-icons/md'
export const Contact = () => {
  return (
    <Flex
      bg='gray.900'
      justifyContent='space-around'
      alignItems='center'
      py='40px'
    >
      <Flex direction='column'>
        <StyledHeading color='#fff' pb='10px'>
          INFORMACION DE CONTACTO
        </StyledHeading>
        <HStack mb='15px'>
          <Icon as={MdLocationOn} color='white' />
          <Box color='whiteAlpha.900'>QUINTINO BOCAYUVA 813 (1216) CABA</Box>
        </HStack>
        <HStack mb='15px'>
          <Icon as={MdPhone} color='white' />
          <Box color='whiteAlpha.900'>+54 9 1121029802</Box>
        </HStack>
        <HStack mb='15px'>
          <Icon as={MdMail} color='white' />
          <Box color='whiteAlpha.900'>administracion@materbionet.com.ar</Box>
        </HStack>
      </Flex>

      <Flex>
        <HStack>
          <Image src={slogo} />{' '}
          <Heading
            color='whiteAlpha.900'
            fontWeight={600}
            fontSize={20}
            letterSpacing={2}
          >
            MATERBIONET SRL
          </Heading>
        </HStack>
      </Flex>
      <Flex direction='column' mt='20px' mb='20px'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.3079393298694!2d-58.42451248438338!3d-34.62165768045489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca56d8192cbb%3A0x229238adc7bc36d4!2sQuintino%20Bocayuva%20813%2C%20C1226%20CABA!5e0!3m2!1ses!2sar!4v1646032775776!5m2!1ses!2sar'
          width='360'
          height='250'
          style={{ border: '0' }}
        />
      </Flex>
    </Flex>
  )
}
