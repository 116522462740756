import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import { GoGlobe } from 'react-icons/go'
import { FaHandsHelping, FaMountain } from 'react-icons/fa'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { ReactElement } from 'react'
import intro from '../../../assets/landing.png'

interface FeatureProps {
  text: string
  iconBg: string
  icon?: ReactElement
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  )
}

export const WhoAreWe = () => {
  return (
    <Container maxW={'6xl'} pt={10} >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            color={'blue.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}
          >
            NUESTRA HISTORIA
          </Text>
          <Heading>¿QUIENES SOMOS?</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
            Somos una empresa fundada por el Ing. Enrique Pavan originariamente
            como Tecnobio en el 2002 con la vocación de brindar servicio técnico
            de calidad a laboratorios de análisis clínicos. Con los valores de
            la humildad, responsabilidad y compromiso fuimos creciendo
            conformando un equipo comprometido con un servicio de calidad en
            tiempo real. Hoy Materbionet se encuentra posicionada como un
            referente de servicio postventa en el área de diagnóstica medica.
            <br />
            Creemos firmemente que una empresa debe sustentarse en los valores
            que rigen su cultura:
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Feature
              icon={<Icon as={GoGlobe} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Responsabilidad'}
            />
            <Feature
              icon={<Icon as={FaHandsHelping} color={'blue.500'} w={5} h={5} />}
              iconBg={useColorModeValue('blue.100', 'blue.900')}
              text={'Compromiso'}
            />
            <Feature
              icon={
                <Icon
                  as={IoIosCheckmarkCircle}
                  color={'green.500'}
                  w={5}
                  h={5}
                />
              }
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Confiabilidad'}
            />
            <Feature
              icon={<Icon as={FaMountain} color={'purple.500'} w={5} h={5} />}
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'Perseverancia'}
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={intro}
            objectFit={'cover'}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  )
}
