import { Flex, SimpleGrid, Heading, Image, Text } from '@chakra-ui/react'
import StyledHeading from '../../../components/StyledHeading'
import mission from '../../../assets/mission.png'
import vision from '../../../assets/vision.png'
import objective from '../../../assets/objective.png'
import { ColoredLine } from '../../../components/ColoredLine'
export const Beliefs = () => {
  return (
    <Flex alignItems='center' pb='40px' direction='column' pt='80px'>
      <StyledHeading mb='20px'>EN QUE CREEMOS</StyledHeading>
      <ColoredLine color='#000' />
      <SimpleGrid
        columns={3}
        justifyContent='center'
        justifyItems='center'
        ml='291px'
        mr='291px'
        mb='40px'
        mt='40px'
        spacing={10}
      >
        <Image src={mission} height='90px' />
        <Image src={vision} height='90px' />
        <Image src={objective} height='90px' />
        <Heading fontWeight={600} fontSize='1.2rem'>
          NUESTRA MISION
        </Heading>
        <Heading fontWeight={600} fontSize='1.2rem'>
          NUESTRA VISION
        </Heading>
        <Heading fontWeight={600} fontSize='1.2rem'>
          NUESTRO OBJETIVO
        </Heading>
        <Text align='center'>
          Ofrecer soluciones integrales al sistema de salud brindando soporte
          técnico, IT y bioquímico con un servicio de alta calidad, pronta
          respuesta y óptimos resultados
        </Text>
        <Text align='center'>
          Ser líderes en el mercado reconocidos por la calidad y excelencia de
          los servicios postventa del área de diagnóstica médica
        </Text>
        <Text align='center'>
          Hacer extraordinariamente las cosas ordinarias
        </Text>
      </SimpleGrid>
    </Flex>
  )
}
